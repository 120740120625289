* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "AgentOrange";
  font-style: normal;
  font-weight: 1 1000;
  src: local("AgentOrange"),
    url("./styles/Fonts/AgentOrange.ttf") format("truetype");
}

@font-face {
  font-family: "BwNistaGeometricDEMO-Regular";
  font-style: normal;
  font-weight: 400;
  src: local("BwNistaGeometricDemo-Regular"),
    url("./styles/Fonts/BwNistaGeometricDEMO-Regular.otf") format("opentype");
}
