@import url("https://use.typekit.net/ojp6eto.css");

.vertical-timeline {
  max-width: 1400px;
  padding: 0px;
}

.vertical-timeline::before {
  width: 1px;
  margin-left: 0px !important;
  height: 86%;
}

.vertical-timeline-element-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  background-color: rgba(21, 76, 102, 0.75);
  /* backdrop-filter: blur(5px); */
  padding: 3.5em 5.5em !important;
  border-radius: 300px;
}
@media only screen and (max-width: 580px) {
  .vertical-timeline-element-content {
    border-radius: 0px;
    padding: 1em !important;
    backdrop-filter: none;
  }
}

.vertical-timeline-element-title {
  font-family: "omnes-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2em;
  padding: 0.5em 0.5em 0 0.5em;
  color: white;
  text-align: center;
}

#description {
  font-family: "omnes-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2em;
  margin: 1.5em 0em 2em 0;
  color: white;
}

.button {
  font-family: "omnes-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: rgba(36, 39, 40, 1);
  border-radius: 5px;
  padding: 0.5em 1em;
  margin-bottom: 1em;
  text-decoration: none;
  background-color: #0ac593;
}

.date {
  color: rgb(201, 251, 255);
}
